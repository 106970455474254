import React, { useEffect, useState } from "react";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { nullCheck } from "../../../util/check";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import BannersApi from "../../../api/bannerApi";
import { deleteFile, fileUpload } from "../../../util/file-management";
import Banner2Api from "../../../api/banner2Api";
import { locationList } from "../../../util/constValue";
import styled from "styled-components";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import Checkbox from "../../../component/atoms/input/checkbox";

function Banner2Edit(props) {
  let { id } = useParams();
  const navigate = useNavigate();

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const [title, setTitle] = useState("");
  let [regionCodeTag, setRegionCodeTag] = useState([]);
  const [link, setLink] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = (await Banner2Api.GetById(id)).data.data;

      setTitle(response.title);
      setIsActive(response.isActive);
      setLink(response.link);
      setFileUrl(response.fileUrl);
      setFile(response.file);

      const regionCodeList = response.regionCodeTag.split("#").filter((item) => item !== "");

      setRegionCodeTag(locationList.filter((item) => regionCodeList.includes(item.code)));
    } catch (error) {
      errorHandler(error);
    }
  };

  const saveFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);

      const regionCodeTagString = "#" + regionCodeTag.map((item) => item.code).join("#");
      const regionNameTagString = "#" + regionCodeTag.map((item) => item.title).join("#");
      formData.append("regionCodeTag", regionCodeTagString);
      formData.append("regionNameTag", regionNameTagString);
      formData.append("isActive", isActive);
      formData.append("link", link);
      if (file) {
        formData.append("file", file);
      }

      await Banner2Api.Update(id, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  //체크 여부 확인
  const isChecked = (e, region) => {
    if (e.target.checked) {
      regionCodeTag.push(region);
    } else {
      regionCodeTag = regionCodeTag.filter((item) => item.code !== region.code);
    }
    setRegionCodeTag([...regionCodeTag]);
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>배너명*</DetailFormTitle>
                <MainInput value={title} type={"text"} placeholder={"배너명을 입력해주세요"} onChange={(e) => setTitle(e.target.value)} />
              </DetailPageForm>

              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>등록 지역*</DetailFormTitle>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", width: "702px" }}>
                  <CheckboxWrapper>
                    <Checkbox
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                      checked={regionCodeTag.length >= 0 && regionCodeTag.length === locationList.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          regionCodeTag = locationList;
                          setRegionCodeTag([...regionCodeTag]);
                        } else {
                          setRegionCodeTag([]);
                        }
                      }}
                    />
                    <DetailFormText
                      style={{
                        color: "#262C31",
                        fontWeight: "500",
                      }}
                    >
                      전체 선택
                    </DetailFormText>
                  </CheckboxWrapper>
                  {locationList.map((item, index) => {
                    return (
                      <CheckboxWrapper key={index}>
                        <Checkbox
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                          checked={regionCodeTag.some((region) => region.code === item.code)}
                          onChange={(e) => isChecked(e, item)}
                        />
                        <DetailFormText
                          style={{
                            color: "#262C31",
                            fontWeight: "500",
                          }}
                        >
                          {item.title}
                        </DetailFormText>
                      </CheckboxWrapper>
                    );
                  })}
                </div>
              </DetailPageForm>

              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>
                  이미지*
                  <br />
                  <span style={{ fontWeight: 400 }}>(권장사이즈 686*198px)</span>
                </DetailFormTitle>
                {fileUrl ? (
                  <ImgPreview
                    fileUrl={fileUrl}
                    onClick={() => {
                      setFileUrl("");
                      setFile("");
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"bannerImg"}
                    accept={"image/*"}
                    onChange={(e) => {
                      fileUpload(e, setFileUrl, setFile);
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>연결링크</DetailFormTitle>
                <MainInput type={"text"} placeholder={"https://"} value={link} onChange={(e) => setLink(e.target.value)} />
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"수정하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                if (nullCheck(title) || nullCheck(fileUrl)) {
                  return toast("모든 내용을 입력해주세요.");
                }
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? <Modal title={modalMsg.update?.title} text={modalMsg.update?.content} setModalOpen={setSaveModalOpen} onClick={saveFunc} /> : ""}
    </>
  );
}

export default Banner2Edit;

const CheckboxWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 8px;
`;
