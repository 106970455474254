import React, { useEffect, useState } from "react";

function NaverLoginCallback(props) {
  // 네이버로그인
  const initNaver = new window.naver.LoginWithNaverId({
    clientId: "HPljfb2H_Y4f7zVt_BaR",
    callbackUrl: `https://admin.orangeschool.kr/naver/login/page/callback`,
    isPopup: false,
    loginButton: { color: "green", type: 2, height: 40 },
  });

  useEffect(() => {
    // initNaver 초기화
    initNaver.init();
  }, []); // 의존성 배열에 isNaverLoginExecuted 추가

  useEffect(() => {
    if (initNaver.accessToken) {
      initNaver.getLoginStatus((status) => {
        if (status) {
          // Flutter로 메시지 전송
          if (window.FlutterChannel) {
            window.FlutterChannel.postMessage(JSON.stringify(initNaver.user));
          }
        }
      });
    }
  }, [initNaver]);

  return (
    <>
      <div>
        <div id="naverIdLogin" style={{ display: "none" }}></div>
        <div />
      </div>
    </>
  );
}

export default NaverLoginCallback;
