import api from "./api.js";

export default class OsPickApi {
  static async Get(data) {
    return await api.get(`admin/picks`, data);
  }

  static async GetById(id) {
    return await api.get(`admin/pick/${id}`);
  }

  static async Del(id) {
    return await api.del(`admin/pick/${id}`);
  }

  static async DelChecked(data) {
    return await api.del(`admin/picks`, "", data);
  }

  static async Update(id, data) {
    return await api.put(`admin/pick/${id}`, data);
  }

  static async Add(data) {
    return await api.post(`admin/pick`, data);
  }

  static async Activate(id, data) {
    return await api.put(`admin/pick/activation/${id}`, data);
  }

  static async GetByIdPublic(id) {
    return await api.get(`pick/${id}`);
  }
}
