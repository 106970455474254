import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { errorHandler } from "../util/error-handler";
import OsPickApi from "../api/osPickApi";
import { useParams } from "react-router-dom";

function PickDetail(props) {
  const [content, setContent] = useState("");
  let { id } = useParams();

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id]);

  const getData = async (id) => {
    try {
      const response = (await OsPickApi.GetByIdPublic(id)).data.data;
      setContent(response.content);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <ReactQuill
        className="read-editor"
        readOnly
        theme="snow"
        content-type="html"
        style={{ height: "100%" }}
        value={content}
        modules={{ toolbar: false }}
      />
    </>
  );
}

export default PickDetail;
