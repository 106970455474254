import api from "./api.js";

export default class StoryApi {
  static async Get(data) {
    return await api.get(`admin/stories`, data);
  }

  static async GetById(id) {
    return await api.get(`admin/story/${id}`);
  }

  static async Del(id) {
    return await api.del(`admin/story/${id}`);
  }

  static async DelChecked(data) {
    return await api.del(`admin/stories`, "", data);
  }

  static async Update(id, data) {
    return await api.put(`admin/story/${id}`, data);
  }

  static async Add(data) {
    return await api.post(`admin/story`, data);
  }

  static async Activate(id, data) {
    return await api.put(`admin/story/activation/${id}`, data);
  }
}
