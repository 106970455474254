import React, { useEffect, useState } from "react";

function NaverLoginPage(props) {
  // 네이버로그인
  const initNaver = new window.naver.LoginWithNaverId({
    clientId: "HPljfb2H_Y4f7zVt_BaR",
    callbackUrl: `https://admin.orangeschool.kr/naver/login/page/callback`,
    isPopup: false,
    loginButton: { color: "green", type: 2, height: 40 },
  });

  const [isNaverLoginExecuted, setIsNaverLoginExecuted] = useState(false);

  useEffect(() => {
    // initNaver 초기화
    initNaver.init();
  }, []); // 의존성 배열에 isNaverLoginExecuted 추가

  useEffect(() => {
    // naverLogin 함수 한 번 실행
    if (!isNaverLoginExecuted) {
      setIsNaverLoginExecuted(true); // 실행 상태 업데이트

      naverLogin();
    }
  }, [isNaverLoginExecuted]); // 의존성 배열에 isNaverLoginExecuted 추가

  const naverLogin = () => {
    const naverLoginBtn = document.getElementById("naverIdLogin").firstChild;
    naverLoginBtn.click();
  };

  return (
    <>
      <div>
        <div id="naverIdLogin" style={{ display: "none" }}></div>
        <div
          onClick={() => {
            naverLogin();
          }}
        />
      </div>
    </>
  );
}

export default NaverLoginPage;
