import api from "./api.js";

export default class ReportApi {
  static async Get(params) {
    return await api.get(`admin/reports`, params);
  }
  static async GetById(reportId, params) {
    return await api.get(`admin/report/${reportId}`, params);
  }
  static async Delete(reportId) {
    return await api.del(`admin/report/${reportId}`);
  }
}
