import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import BannersApi from "../../../api/bannerApi";
import Banner2Api from "../../../api/banner2Api";

function Banner2Detail(props) {
  let { id } = useParams();
  const navigate = useNavigate();

  const [delModalOpen, setDelModalOpen] = useState(false);

  const [title, setTitle] = useState("");
  const [regionCodeTag, setRegionCodeTag] = useState("");
  const [regionNameTag, setRegionNameTag] = useState("");
  const [viewCount, setViewCount] = useState("");
  const [isActive, setIsActive] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [link, setLink] = useState("");

  const [fileUrl, setFileUrl] = useState("");
  const [originFileName, setOriginFileName] = useState("");
  const [serverFileName, setServerFileName] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = (await Banner2Api.GetById(id)).data.data;

      setTitle(response.title);
      setRegionCodeTag(response.regionCodeTag);
      setRegionNameTag(response.regionNameTag.replace(/#/g, ",").replace(/^,/, ""));
      setViewCount(response.viewCount);
      setIsActive(response.isActive);
      setUpdatedAt(response.updatedAt);
      setLink(response.link);
      setFileUrl(response.fileUrl);
      setOriginFileName(response.originFileName);
      setServerFileName(response.serverFileName);
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteFunc = async () => {
    try {
      await Banner2Api.Delete(id);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.service_banner_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "287px" }}>
            <DetailPageTitle>상세보기</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>배너명</DetailFormTitle>
                <DetailFormText>{title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>조회 수 (클릭 수)</DetailFormTitle>
                <DetailFormText>{viewCount.toLocaleString()}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>등록 지역</DetailFormTitle>
                <DetailFormText>{regionNameTag}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시 상태</DetailFormTitle>
                <DetailFormText>{isActive ? "활성" : "비활성"}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>마지막 수정일</DetailFormTitle>
                <DetailFormText>{moment(updatedAt).format("YYYY/MM/DD")}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이미지</DetailFormTitle>
                <FileImgPreview fileUrl={fileUrl} originFileName={originFileName} serverFileName={serverFileName} />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>연결링크</DetailFormTitle>
                <DetailFormText style={{ wordBreak: "break-all" }}>{link}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Banner2Detail;
