import api from "./api.js";

export default class Banner2Api {
  static async Get(data) {
    return await api.get(`admin/bannerV2s`, data);
  }

  static async GetById(banner2Id) {
    return await api.get(`admin/bannerV2/${banner2Id}`);
  }

  static async Add(data) {
    return await api.post(`admin/bannerV2`, data);
  }

  static async Update(banner2Id, data) {
    return await api.put(`admin/bannerV2/${banner2Id}`, data);
  }

  static async Delete(banner2Id) {
    return await api.del(`admin/bannerV2/${banner2Id}`);
  }

  static async DeleteChecked(data) {
    return await api.del(`admin/bannerV2s`, "", data);
  }

  static async Activate(banner2Id, data) {
    return await api.put(`admin/bannerV2/activation/${banner2Id}`, data);
  }
}
