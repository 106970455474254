import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { nullCheck } from "../../util/check";

function TableTab({ style, list, onClick }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTab = useMemo(() => {
    if (nullCheck(searchParams.get("tab"))) {
      return "";
    }
    return searchParams.get("tab");
  }, [searchParams]);

  return (
    <>
      <TableTabWrapper style={{ ...style }}>
        {list.map((item, index) => {
          return (
            <Tab
              key={index}
              style={{
                color: `${selectedTab === item.type ? "#27BEFF" : ""}`,
                borderBottom: `${selectedTab === item.type ? "2px solid #27BEFF" : ""}`,
              }}
              onClick={() => {
                onClick(item.type);
              }}
            >
              {item.title}
            </Tab>
          );
        })}
      </TableTabWrapper>
    </>
  );
}

export default TableTab;

const TableTabWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const Tab = styled.div.attrs((props) => {})`
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px 10px;
  color: #adb4ba;
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px; /* 162.5% */
  letter-spacing: -0.054px;
  cursor: pointer;
`;
