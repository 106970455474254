export const locationList = [
  {
    code: "1100000000",
    title: "서울특별시",
  },
  {
    code: "2600000000",
    title: "부산광역시",
  },
  {
    code: "2700000000",
    title: "대구광역시",
  },
  {
    code: "2800000000",
    title: "인천광역시",
  },
  {
    code: "2900000000",
    title: "광주광역시",
  },
  {
    code: "3000000000",
    title: "대전광역시",
  },
  {
    code: "3100000000",
    title: "울산광역시",
  },
  {
    code: "3611000000",
    title: "세종특별자치시",
  },
  {
    code: "4100000000",
    title: "경기도",
  },
  {
    code: "4300000000",
    title: "충청북도",
  },
  {
    code: "4400000000",
    title: "충청남도",
  },
  {
    code: "4500000000",
    title: "전라북도",
  },
  {
    code: "4600000000",
    title: "전라남도",
  },
  {
    code: "4700000000",
    title: "경상북도",
  },
  {
    code: "4800000000",
    title: "경상남도",
  },
  {
    code: "5000000000",
    title: "제주특별자치도",
  },
  {
    code: "5100000000",
    title: "강원특별자치도",
  },
];
