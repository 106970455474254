import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import EditBtn from "../../component/atoms/button/editBtn";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import { errorHandler } from "../../util/error-handler";
import { route } from "../../router/route";
import DelBtn from "../../component/atoms/button/delBtn";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import moment from "moment";
import PopupsApi from "../../api/popupApi";
import OsPickApi from "../../api/osPickApi";
import StoryApi from "../../api/storyApi";
import FileImgPreview from "../../component/molecules/fileImgPreview";

function StoryDetail(props) {
  let { id } = useParams();
  const navigate = useNavigate();

  const [delModalOpen, setDelModalOpen] = useState(false);

  const [viewCount, setViewCount] = useState(0);
  const [email, setEmail] = useState("");
  const [regionName, setRegionName] = useState("");
  const [parentNickName, setParentNickName] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [images, setImages] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = (await StoryApi.GetById(id)).data.data;

      setViewCount(response.viewCount);
      setEmail(response.email);
      setRegionName(getRegionName(response.regionCodeTag, response.regionNameTag));
      setParentNickName(response.parentNickName);
      setTitle(response.title);
      setContent(response.content);
      setImages(response.images);
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteFunc = async () => {
    try {
      await StoryApi.Del(id);
      setDelModalOpen(false);
      toast(toastMsg.delete);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getRegionName = (regionCodeTag, regionNameTag) => {
    if (regionCodeTag === "#99999") {
      return "전국";
    } else {
      return regionNameTag.split("#").filter(Boolean).join(",");
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "287px" }}>
            <DetailPageTitle>상세보기</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>조회수</DetailFormTitle>
                <DetailFormText>{viewCount.toLocaleString()}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>구분</DetailFormTitle>
                <DetailFormText>유저 게시판</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>작성자이메일</DetailFormTitle>
                <DetailFormText>{email}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>작성게시판</DetailFormTitle>
                <DetailFormText>{regionName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>작성자닉네임</DetailFormTitle>
                <DetailFormText>{parentNickName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시물 제목</DetailFormTitle>
                <DetailFormText>{title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>게시물 본문</DetailFormTitle>
                <DetailFormText>
                  <div
                    className="quill-detail"
                    style={{ wordBreak: "break-all", maxWidth: "740px" }}
                    dangerouslySetInnerHTML={{
                      __html: content,
                    }}
                  />
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>게시물 본문</DetailFormTitle>
                <div style={{ display: "flex", gap: "5px", flexWrap: "wrap", width: "744px" }}>
                  {images?.length > 0
                    ? images?.map((item, index) => {
                        return <FileImgPreview key={index} fileUrl={item.imageUrl} originFileName={item.originFileName} serverFileName={item.serverFileName} />;
                      })
                    : null}
                </div>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default StoryDetail;
