import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import EditBtn from "../../component/atoms/button/editBtn";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import { errorHandler } from "../../util/error-handler";
import { route } from "../../router/route";
import DelBtn from "../../component/atoms/button/delBtn";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import moment from "moment";
import PopupsApi from "../../api/popupApi";
import OsPickApi from "../../api/osPickApi";
import ReactQuill from "react-quill";

function OsPickDetail(props) {
  let { id } = useParams();
  const navigate = useNavigate();

  const [delModalOpen, setDelModalOpen] = useState(false);

  const [number, setNumber] = useState(-1);
  const [viewCount, setViewCount] = useState(0);
  const [title, setTitle] = useState("");
  const [previewContent, setPreviewContent] = useState("");
  const [content, setContent] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [link, setLink] = useState("");
  const [linkBtnName, setLinkBtnName] = useState("");
  const [pickTypeTitle, setPickTypeTitle] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = (await OsPickApi.GetById(id)).data.data;

      setNumber(response.number);
      setViewCount(response.viewCount);
      setTitle(response.title);
      setPreviewContent(response.previewContent);
      setContent(response.content);
      setUpdatedAt(response.createdAt);
      setLink(response.link);
      setLinkBtnName(response.linkBtnName);
      setPickTypeTitle(response.pickTypeTitle ? response.pickTypeTitle : "전체");
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteFunc = async () => {
    try {
      await OsPickApi.Del(id);
      setDelModalOpen(false);
      toast(toastMsg.delete);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.os_pick_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "287px" }}>
            <DetailPageTitle>상세보기</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>번호</DetailFormTitle>
                <DetailFormText>{number}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>조회수</DetailFormTitle>
                <DetailFormText>{viewCount.toLocaleString()}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>구분</DetailFormTitle>
                <DetailFormText>{pickTypeTitle}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>등록관리자이메일</DetailFormTitle>
                <DetailFormText>{pickTypeTitle}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>마지막 수정일</DetailFormTitle>
                <DetailFormText>{moment(updatedAt).format("YYYY/MM/DD")}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시물 제목</DetailFormTitle>
                <DetailFormText>{title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시물 본문 미리보기</DetailFormTitle>
                <DetailFormText>{previewContent}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>게시물 본문</DetailFormTitle>
                {/* <div
                  className="ql-editor"
                  style={{ wordBreak: "keep-all", maxWidth: "740px" }}
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
                 */}
                <ReactQuill className="read-editor" readOnly theme="snow" content-type="html" style={{ width: "744px", height: "100%" }} value={content} modules={{ toolbar: false }} />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>관련 링크</DetailFormTitle>
                <DetailFormText style={{ wordBreak: "break-all" }}>{link}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>링크버튼명</DetailFormTitle>
                <DetailFormText style={{ wordBreak: "break-all" }}>{linkBtnName}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default OsPickDetail;
