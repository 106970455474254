import api from "./api.js";

export default class SuggestApi {
  static async Get(params) {
    return await api.get(`admin/suggests`, params);
  }
  static async GetById(suggestId, params) {
    return await api.get(`admin/suggest/${suggestId}`, params);
  }
  static async Delete(suggestId) {
    return await api.del(`admin/suggest/${suggestId}`);
  }
}
