import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableCount from "../../../component/molecules/tableCount";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Checkbox from "../../../component/atoms/input/checkbox";
import Th from "../../../component/atoms/layout/table/th";
import Td from "../../../component/atoms/layout/table/td";
import Pagenation from "../../../component/molecules/pagenation";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import TableFilterManager from "../../../component/atoms/layout/table/tableFilterManager";
import ThText from "../../../component/atoms/text/table/thText";
import TdText from "../../../component/atoms/text/table/tdText";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import { modalMsg } from "../../../util/modalMsg";
import AddBtn from "../../../component/atoms/button/addBtn";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import PopupsApi from "../../../api/popupApi";

function Popup(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [list, setList] = useState([]);

  const [filterOpen, setFilterOpen] = useState(-1);
  const [activeId, setActiveId] = useState(null);
  const [activeModalOpen, setActiveModalOpen] = useState(false);
  const [deactiveModalOpen, setDeactiveModalOpen] = useState(false);

  const [delId, setDelId] = useState(-1);
  const [delIdList, setDelIdList] = useState([]);
  const [delListModalOpen, setDelListModalOpen] = useState(false);
  const [delModalOpen, setDelModalOpen] = useState(false);

  const [keyword, setKeyword] = useState("");
  const { page } = useParams();
  const [searchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getList();
  }, [location]);

  const getList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: page - 1,
        size: pagination.size,
      };

      let list = (await PopupsApi.Get(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const activeFunc = async (activation) => {
    try {
      const data = {
        activation: activation,
      };
      await PopupsApi.Activate(activeId, data);
      toast(toastMsg.create);
      setFilterOpen(-1);
      setActiveModalOpen(false);
      setDeactiveModalOpen(false);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteFunc = async () => {
    try {
      const idList = delIdList.map((item) => {
        return item.id;
      });

      const formData = new FormData();
      idList.map((item) => formData.append("idList", item));

      await PopupsApi.DeleteChecked(formData);
      toast(toastMsg.delete);
      setDelListModalOpen(false);
      setDelIdList([]);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>팝업 관리</TableTitle>
          <TableTop>
            <TableCount text={"항목"} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.service_popup}/1?keyword=${keyword}`);
                  }
                }}
                onClick={() => {
                  navigate(`${route.service_popup}/1?keyword=${keyword}`);
                }}
              />
              <DelBtn
                text={"삭제"}
                onClick={() => {
                  const delIdList = list.filter((item) => item.checked);
                  if (delIdList.length <= 0) {
                    toast("항목을 선택해주세요");
                    return;
                  }
                  setDelIdList([...delIdList]);
                  setDelListModalOpen(true);
                }}
              />
              <AddBtn onClick={() => navigate(route.service_popup_add)} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              <col width="90px" />
              <col width="290px" />
              <col width="160px" />
              <col width="152px" />
              <col width="120px" />
              <col width="149px" />
              <col width="72px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={list.filter((item) => item.checked).length === list.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <ThText>제목</ThText>
                </Th>
                <Th>
                  <ThText>게시일</ThText>
                </Th>
                <Th>
                  <ThText>조회수(클릭수)</ThText>
                </Th>
                <Th>
                  <ThText>게시 상태</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "95px" }}>삭제하기</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "17px" }}>상세보기</ThText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setList([...list]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "290px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.title}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText>{moment(item.createdAt).format("YYYY/MM/DD")}</TdText>
                    </Td>
                    <Td>
                      <TdText>{item.viewCount?.toLocaleString()}</TdText>
                    </Td>
                    <Td>
                      <TableFilterManager
                        onClick={() => {
                          filterOpen === index ? setFilterOpen(-1) : setFilterOpen(index);
                        }}
                        isOpen={filterOpen === index}
                        selectedValue={item.isActive}
                        list={[
                          {
                            value: true,
                            title: "활성",
                            onClick: () => {
                              setActiveId(item.id);
                              setActiveModalOpen(true);
                            },
                          },
                          {
                            value: false,
                            title: "비활성",
                            onClick: () => {
                              setActiveId(item.id);
                              setDeactiveModalOpen(true);
                            },
                          },
                        ]}
                      />
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "95px",
                        }}
                      >
                        <TableDelBtn
                          onClick={() => {
                            setDelId(item.id);
                            setDelModalOpen(true);
                          }}
                        />
                      </div>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "17px",
                        }}
                      >
                        <DetailBtn
                          onClick={() => {
                            navigate(route.service_popup_detail + `/${item.id}`);
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagenation
            route={route.service_popup}
            queryString={`keyword=${keyword}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>

        {delListModalOpen ? (
          <Modal
            title={modalMsg.delete?.title}
            text={modalMsg.delete?.content}
            setModalOpen={setDelListModalOpen}
            onClick={() => {
              deleteFunc();
            }}
          />
        ) : (
          ""
        )}
        {delModalOpen ? (
          <Modal
            title={modalMsg.delete?.title}
            text={modalMsg.delete?.content}
            setModalOpen={setDelModalOpen}
            onClick={async () => {
              try {
                await PopupsApi.Delete(delId);
                setDelModalOpen(false);
                getList();
                toast(toastMsg.delete);
              } catch (error) {
                errorHandler(error);
              }
            }}
          />
        ) : (
          ""
        )}
        {deactiveModalOpen ? (
          <Modal
            title={modalMsg.deactivate?.title}
            setModalOpen={setDeactiveModalOpen}
            onClick={() => {
              activeFunc(false);
            }}
          />
        ) : (
          ""
        )}
        {activeModalOpen ? (
          <Modal
            title={modalMsg.activate?.title}
            setModalOpen={setActiveModalOpen}
            onClick={() => {
              activeFunc(true);
            }}
          />
        ) : (
          ""
        )}
      </MainLayout>
    </>
  );
}

export default Popup;
