import "./App.css";
import Router from "./router/router";
import { RecoilRoot } from "recoil";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <Router />
      </RecoilRoot>
      <ToastContainer
        className="toast"
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
      />
    </div>
  );
}

export default App;
